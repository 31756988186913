import type { Contrat } from '~/types/models/contrat';
import { AvenantStatus } from '~/types/Enums';
import { usePvHtAvenant } from './avenants/usePvHtAvenant';

export const usePvHtAvenantsSignes = (contrat: Contrat) => {
  return (
    contrat?.avenants
      ?.filter((a) => a.statut === AvenantStatus.SIGNE)
      .reduce((acc: number, avenant: any) => {
        return acc + usePvHtAvenant(avenant);
      }, 0) || 0
  );
};
