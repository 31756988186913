import { JalonPnl } from '~/types/Enums';
import type { Contrat } from '~/types/models/contrat';

export const useJalonPnl = (contrat: Contrat, jalon: JalonPnl, entityId?: number) => {
  const pnl = contrat?.pnlProjet;
  const datas = pnl?.datas;
  if (!datas) return null;
  const pnlData = datas.find((d) => d.event === jalon && (!entityId || d.entityId === entityId));
  return pnlData;
};
