import { usePvHtOption } from '~/composables/metres/options/usePvHtOption';
import type { ArticleLibreAvenant, Avenant } from '~/types/models/avenant';
import type { OptionMetre } from '~/types/models/optionMetre';

export const usePvHtAvenant = (avenant: Avenant) => {
  let pvHt = avenant.options.reduce((acc: number, option: OptionMetre) => {
    return acc + usePvHtOption(option);
  }, 0);

  pvHt += avenant.articlesLibres.reduce((acc: number, article: ArticleLibreAvenant) => {
    return acc + useUnapplyTva(article.pvTTC, useTauxTva(article.tva));
  }, 0);

  return pvHt;
};
