import type { Contrat } from '~/types/models/contrat';
import { usePrixConvenuHt } from './usePrixConvenuHt';
import { usePvHtAvenantsSignes } from './usePvHtAvenantsSignes';

export const useDernierPrixConnuHt = (contrat: Contrat) => {
  const lastPnl = contrat.pnlProjet?.datas.slice(-1)[0];
  if (lastPnl) {
    return useRound(lastPnl.pvsHt.reduce((acc, pv) => acc + pv.pv, 0));
  } else {
    return useRound(usePrixConvenuHt(contrat) + usePvHtAvenantsSignes(contrat));
  }
};
