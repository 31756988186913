import type { OptionMetre } from '~/types/models/optionMetre';
import { usePvHtLigne } from '../lignes/usePvHtLigne';

export const usePvHtOption = (option: OptionMetre) => {
  return (
    option.lignes
      ?.filter((l) => l.compris)
      .reduce((acc, ligne) => {
        return acc + usePvHtLigne(ligne);
      }, 0) || 0
  );
};
